import _interopRequireDefault2 from "@babel/runtime/helpers/interopRequireDefault";
import _interopRequireWildcard2 from "@babel/runtime/helpers/interopRequireWildcard";
import _Popper2 from "./Popper";
import _Manager2 from "./Manager";
import _Reference2 from "./Reference";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Popper", {
  enumerable: true,
  get: function get() {
    return _Popper.default;
  }
});
Object.defineProperty(exports, "placements", {
  enumerable: true,
  get: function get() {
    return _Popper.placements;
  }
});
Object.defineProperty(exports, "Manager", {
  enumerable: true,
  get: function get() {
    return _Manager.default;
  }
});
Object.defineProperty(exports, "Reference", {
  enumerable: true,
  get: function get() {
    return _Reference.default;
  }
});

var _Popper = _interopRequireWildcard(_Popper2);

var _Manager = _interopRequireDefault(_Manager2);

var _Reference = _interopRequireDefault(_Reference2);

export default exports;
export const __esModule = exports.__esModule,
      Popper = exports.Popper,
      placements = exports.placements,
      Manager = exports.Manager,
      Reference = exports.Reference;